<template>
  <div class="hero-foot">
      <div class="container">
          <div class="tabs is-centered">
              <ul>
                  <li>©HIGEDARUMA</li>
              </ul>
          </div>
      </div>
  </div>
</template>

<style scoped lang="scss">
</style>