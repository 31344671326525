<template>
  <div class="hero-head">
      <nav class="navbar">
          <div class="container">
              <div class="navbar-brand">
                  <a class="navbar-item" href="../">
                      <img src="../assets/logo.png" alt="Logo">
                  </a>
                  <span class="navbar-burger burger" data-target="navbarMenu">
                      <span></span>
                      <span></span>
                      <span></span>
                  </span>
              </div>
              <div id="navbarMenu" class="navbar-menu">
                  <div class="navbar-end">
                      <div class="tabs is-right">
                          <ul>
                              <li @click="isSelect(1)" v-bind:class="{'is-active': this.isActive == 1}"><router-link to="/">HOME</router-link></li>
                              <li @click="isSelect(2)" v-bind:class="{'is-active': this.isActive == 2}"><router-link to="/make">MAKES</router-link></li>
                              <li @click="isSelect(3)" v-bind:class="{'is-active': this.isActive == 3}"><router-link to="/profile">PROFILE</router-link></li>
                              <li @click="isSelect(4)" v-bind:class="{'is-active': this.isActive == 4}"><router-link to="/contact">CONTACT</router-link></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </nav>
  </div>
</template>
<script>

export default {
  data () {
    return {
      isActive: 1
    }
  },
  mounted () {
    var burger = document.querySelector('.burger');
    var menu = document.querySelector('#'+burger.dataset.target);
    burger.addEventListener('click', function() {
        burger.classList.toggle('is-active');
        menu.classList.toggle('is-active');
    });      
  },
  methods:{
    isSelect(num){
      this.isActive = num;
    }      
  }
}
</script>
<style scoped lang="scss">
</style>