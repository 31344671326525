<template>
  <section class="hero is-fullheight is-default is-bold">
    <main-header/>
    
    <transition name="router-transition" enter-active-class="animated fadeIn">
      <router-view/>
    </transition>      
    <main-footer />
  </section>
  
</template>

<script>
import MainHeader from './components/MainHeader.vue'
import MainFooter from './components/MainFooter.vue'

export default {
  name: 'App',
  components: {
    MainHeader,
    MainFooter,
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.css";
@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import "bulma/bulma.sass";

body {
  font-family: 'Open Sans';
}
</style>