import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'Top',
    component: function () {
      return import('../views/TopContents.vue')
    }
  },
  {
    path: '/make',
    name: 'Make',
    component: function () {
      return import('../views/MakeContents.vue')
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: function () {
      return import('../views/ProfileContents.vue')
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: function () {
      return import('../views/Contact.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
